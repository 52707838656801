<template>
  <ais-clear-refinements v-if="showResetButton" :included-attributes="['query', 'categories.lvl0', 'categories.lvl1']">
    <template #default="{ refine }">
      <div class="search-form__reset-wrap">
        <a :href="createCurrentURL()" class="search-form__reset-btn link-secondary" @click.prevent="onReset(refine)"> Reset filters </a>
      </div>
    </template>
  </ais-clear-refinements>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SEARCH_CONTENT_TYPE_ALL } from '~/constants/search';
import { useResourcesSearchStore } from '~/stores/resources-search';

const resourcesSearch = useResourcesSearchStore();

const activeContentType = computed(() => resourcesSearch.activeContentType);
const query = computed(() => resourcesSearch.query);
const category = computed(() => resourcesSearch.category);

const createCurrentURL = () => {
  return window.location.origin + window.location.pathname;
};

const showResetButton = computed(() => {
  return query.value || category.value || activeContentType.value !== SEARCH_CONTENT_TYPE_ALL;
});

const onReset = (refine) => {
  resourcesSearch.clear();
  refine();
};
</script>
