<template>
  <div class="banner" :class="[`is-${item.backgroundColor}`]">
    <p class="banner__text">
      {{ item.title }}
    </p>
    <div class="banner__img-wrap">
      <picture-basic v-if="item.image" v-bind="imageBindings" />
    </div>
    <pt-button v-if="item.button.has" v-bind="getButtonBindings(item.button)" :size="isMobile() ? '' : 'full'">
      {{ item.button.label }}
    </pt-button>
  </div>
</template>
<script setup lang="ts">
import { useImages } from '~/composables/images';
import PtButton from '~/components/global/elements/PtButton.vue';
import PictureBasic from '~/components/global/images/PictureBasic.vue';
import type { LooseObject } from '~/types/generic';
import { useButtons } from '~/composables/buttons';

const props = defineProps({
  item: {
    type: Object as () => LooseObject,
    required: true,
  },
});

const { getPictureBindings } = useImages();
const { getButtonBindings } = useButtons();

const imageBindings = computed(() => {
  if (props.item.image) {
    return getPictureBindings(props.item.image, { ratio: 0.65 });
  }
  return null;
});

const isMobile = () => window.innerWidth <= 768;
</script>
