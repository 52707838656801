<template>
  <div class="search-menu">
    <search-menu-list :items="categories" :refine="refine" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import SearchMenuList from '~/components/global/components/search/SearchMenuList.vue';

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
  refine: {
    type: Function,
    required: true,
  },
});

const setChildCategories = (categories, children) => {
  const childCategories = categories.split('>');
  const category = childCategories[childCategories.length - 1]?.trim();

  if (category) {
    const mainCategory = children.find((item) => item?.title === category);

    if (!mainCategory) {
      children.push({
        title: category,
        value: categories,
        children: [],
      });
    } else {
      setChildCategories(categories, mainCategory.children);
    }
  }
};

const categories = computed(() => {
  const result = [];

  Object.keys(props.items).forEach((level, index) => {
    if (index === 0) {
      props.items[level].forEach((category) => {
        const mainCategory = result.find((item) => item?.title === category);

        if (!mainCategory) {
          result.push({
            title: category,
            value: category,
            level,
            children: [],
          });
        }
      });
    } else {
      props.items[level].forEach((categories) => {
        const mainCategory = result.find((item) => item?.title === categories.split('>')[0].trim());

        if (mainCategory) {
          setChildCategories(categories, mainCategory.children);
        }
      });
    }
  });

  return result;
});
</script>
