<template>
  <div class="search-form__types">
    <client-only>
      <swiper
        ref="typesSlider"
        :modules="[Mousewheel]"
        :mousewheel="true"
        slides-per-view="auto"
        :centered-slides="false"
        :space-between="12"
        @swiper="onSwiper"
      >
        <swiper-slide>
          <button
            key="all"
            class="search-form__types-item"
            :class="{ 'is-selected': SEARCH_CONTENT_TYPE_ALL === activeContentType }"
            @click="selectSearchContentType(SEARCH_CONTENT_TYPE_ALL)"
          >
            All
          </button>
        </swiper-slide>
        <swiper-slide v-for="contentType in types" :key="contentType">
          <button
            class="search-form__types-item"
            :class="{ 'is-selected': contentType === activeContentType }"
            @click="selectSearchContentType(contentType)"
          >
            {{ SEARCH_CONTENT_TYPE_LABELS[contentType] }}
          </button>
        </swiper-slide>
      </swiper>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel } from 'swiper/modules';
import { useResourcesSearchStore } from '~/stores/resources-search';
import { SEARCH_CONTENT_TYPE_ALL, SEARCH_CONTENT_TYPE_LABELS } from '~/constants/search';

defineProps({
  types: {
    type: Array as () => string[],
    required: true,
  },
});

const resourcesSearch = useResourcesSearchStore();
const activeContentType = computed(() => resourcesSearch.activeContentType);
const typesSlider = ref(null);

const onSwiper = (swiper) => {
  typesSlider.value = swiper;

  swiper.on('click', () => {
    swiper.slideTo(swiper.clickedIndex);
  });
};

const selectSearchContentType = (t: string) => {
  resourcesSearch.setActiveContentType(t);
};

const slideToFirst = () => {
  typesSlider.value?.slideTo(0);
};

watch(activeContentType, (val) => {
  if (val === SEARCH_CONTENT_TYPE_ALL) {
    slideToFirst();
  }
});
</script>
