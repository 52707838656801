<template>
  <div class="has-marg-top-l">
    <client-only>
      <ais-instant-search class="search-widget" :index-name="pagesIndex" :routing="routing" :search-client="client" :insights="true">
        <ais-autocomplete>
          <template #default="{ currentRefinement, refine }">
            <search-autocomplete :refine="refine" :current-refinement="currentRefinement" :router="routing.router" />
          </template>
        </ais-autocomplete>
        <search-types ref="searchTypesComponent" :types="searchContentTypes" />

        <div
          class="block-featured__inner"
          :class="{
            'has-menu': !isDigitalScreen,
          }"
        >
          <div v-if="!isDigitalScreen" class="search-form__sidebar">
            <categories-menu v-once>
              <template #reset>
                <search-reset />
              </template>

              <ais-hierarchical-menu :attributes="['categories.lvl0', 'categories.lvl1']">
                <template #default="{ refine }">
                  <search-menu :items="searchCategoriesTree" :refine="refine" />
                </template>
              </ais-hierarchical-menu>
            </categories-menu>

            <banner v-if="showBanner" :item="searchBanner" />
          </div>

          <search-results :types="searchContentTypes" :router="routing.router" :is-digital-screen="isDigitalScreen" />
        </div>
      </ais-instant-search>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useNuxtApp, useRuntimeConfig } from '#app';
import history from 'instantsearch.js/es/lib/routers/history';
import SearchAutocomplete from '~/components/global/components/search/SearchAutocomplete.vue';
import type { SearchConfig } from '~/plugins/search';
import { SEARCH_CONTENT_TYPE_PAGES, SEARCH_CONTENT_TYPE_RESOURCES, SEARCH_CONTENT_TYPE_FAQS } from '@/constants/search';
import { useGlobalsStore } from '~/stores/globals';
import CategoriesMenu from '~/components/global/components/categories-menu/CategoriesMenu.vue';
import SearchMenu from '~/components/global/components/search/SearchMenu.vue';
import { useResourcesSearchStore } from '~/stores/resources-search';
import SearchReset from '~/components/global/components/search/SearchReset.vue';
import SearchResults from '~/components/global/components/search/SearchResults.vue';
import SearchTypes from '~/components/global/components/search/SearchTypes.vue';
import Banner from '~/components/global/components/banner/Banner.vue';

const props = defineProps({
  isDigitalScreen: Boolean,
});

const config = useRuntimeConfig();
const { client } = useNuxtApp().$search as SearchConfig;
const globals = useGlobalsStore();
const resourcesSearch = useResourcesSearchStore();
const searchSettings = globals.searchSettings;

const digitalScreenContentTypes = [SEARCH_CONTENT_TYPE_FAQS, SEARCH_CONTENT_TYPE_RESOURCES, SEARCH_CONTENT_TYPE_PAGES];

const pagesIndex = computed(() => config.public.algoliaPagesIndex);

const routing = {
  router: history({
    cleanUrlOnDispose: false,
  }),
  stateMapping: {
    stateToRoute: (uiState) => {
      const query = uiState[pagesIndex.value].query;

      return { query };
    },
    routeToState: (routeState) => {
      const query = routeState.query;
      resourcesSearch.setQuery(query);

      return {
        [pagesIndex.value]: { query },
      };
    },
  },
};

const searchCategoriesTree = computed(() => globals.searchSettings?.categories_tree);

const searchContentTypes = computed(() => {
  if (props.isDigitalScreen) {
    return globals.searchSettings?.search_content_types_order.filter((type) => {
      return digitalScreenContentTypes.includes(type);
    });
  }

  return globals.searchSettings?.search_content_types_order;
});

const searchBanner = computed(() => {
  return {
    title: searchSettings.banner_title,
    enabled: searchSettings.banner_enabled,
    backgroundColor: searchSettings.banner_background_colour,
    image: searchSettings.banner_image,
    button: {
      has: searchSettings.banner_button_has_cta,
      label: searchSettings.banner_button_label,
      link: searchSettings.banner_button_link,
    },
  };
});

const showBanner = computed(() => searchBanner.value && searchBanner.value.enabled);
</script>
