<template>
  <ul class="search-menu__list">
    <li v-for="(item, index) in items" :key="index" class="search-menu__list-item" :class="{ 'is-top-level': item.level }">
      <a
        href=""
        class="search-menu__list-link"
        :class="{
          'is-top-level': item.level,
          'has-children': item.children.length > 0,
          'is-selected': isSelected(item.title).value,
        }"
        data-search-menu-link
        @click.prevent="handleClick(item)"
      >
        <span>{{ item.title }}</span>

        <span class="search-menu__list-arrow">
          <svg v-if="item.children.length > 0" width="17" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.85788 1.42894L8.92894 8.5L16 1.42894" stroke="#28294E" stroke-width="2" stroke-linecap="round" />
          </svg>
        </span>
      </a>

      <search-menu-list v-if="isShow(item.children, item.title).value" :items="item.children" :refine="refine" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { LooseObject } from '~/types/generic';
import { useResourcesSearchStore } from '~/stores/resources-search';

const props = defineProps({
  items: {
    type: Array as () => LooseObject,
    required: true,
  },
  refine: {
    type: Function,
    required: true,
  },
});

const resourcesSearch = useResourcesSearchStore();
const activeCategories = computed(() => resourcesSearch.activeCategories);

const handleClick = (item) => {
  removeBgWhiteClass();
  props.refine(item.value);

  resourcesSearch.setActiveCategories(item);
  resourcesSearch.setCategory(item.title);
};

const isShow = (children: [], title: string) => computed(() => activeCategories.value.has(title) && children.length > 0);
const isSelected = (title: string) => computed(() => activeCategories.value.has(title));

const removeBgWhiteClass = () => {
  const links = document.body.querySelectorAll('[data-search-menu-link]');
  links.forEach((link) => {
    link.classList.remove('is-bg-white');
  });
};
</script>
