<template>
  <div ref="autocompleteContainer" />
</template>

<script setup lang="ts">
import instantsearch from 'instantsearch.js';
import { useNuxtApp, useRuntimeConfig } from '#app';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import debounce from 'lodash/debounce';
import { autocomplete } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import type { SearchConfig } from '~/plugins/search';
import { useResourcesSearchStore } from '~/stores/resources-search';

const props = defineProps({
  refine: {
    type: Function,
    required: true,
  },
  currentRefinement: {
    type: String,
    required: true,
  },
  router: {
    type: Object,
    required: true,
  },
});

const { client } = useNuxtApp().$search as SearchConfig;
const config = useRuntimeConfig();
const resourcesSearch = useResourcesSearchStore();
const autocompleteContainer = ref(null);
const autocompleteInstance = ref(null);
const querySuggestionIndex = config.public.algoliaQuerySuggestionsIndex;

const query = computed(() => resourcesSearch.query);

const instantSearchInstance = instantsearch({
  indexName: config.public.algoliaPagesIndex,
  searchClient: client,
  future: {
    preserveSharedStateOnUnmount: true,
  },
});

const instantSearchRouter = props.router;

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
  searchClient: client,
  indexName: querySuggestionIndex,
  transformSource({ source }) {
    return {
      ...source,
      sourceId: 'querySuggestionsPlugin',
      onSelect({ item }) {
        setInstantSearchUiState({ query: item.query });
      },
      getItems(params) {
        if (!params.state.query) {
          return [];
        }
        return source.getItems(params);
      },
    };
  },
});

function setInstantSearchUiState({ query }) {
  instantSearchRouter.write({ query });
  resourcesSearch.setQuery(query);
  props.refine(query);
}

const debouncedSetInstantSearchUiState = debounce(setInstantSearchUiState, 500);

onMounted(() => {
  instantSearchInstance.start();

  autocompleteInstance.value = autocomplete({
    container: autocompleteContainer.value,
    placeholder: 'Search the Public Trust website...',
    detachedMediaQuery: 'none',
    plugins: [querySuggestionsPlugin],
    initialState: { query: props.currentRefinement || '' },
    onSubmit({ state }) {
      setInstantSearchUiState({ query: state.query });
    },
    onReset() {
      setInstantSearchUiState({ query: '' });
    },
    onStateChange({ prevState, state }) {
      if (prevState.query !== state.query) {
        debouncedSetInstantSearchUiState({ query: state.query });
      }
    },
  });
});

onBeforeUnmount(() => {
  autocompleteInstance.value?.destroy();
});

watch(query, (newValue, oldValue) => {
  if (!newValue && oldValue) {
    autocompleteInstance.value?.setQuery('');
  }
});
</script>
