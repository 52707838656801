import { SEARCH_CONTENT_TYPE_ALL } from '~/constants/search';

export const useResourcesSearchStore = defineStore('resourcesSearch', {
  state: () => ({
    query: null,
    category: null,
    activeContentType: SEARCH_CONTENT_TYPE_ALL,
    activeCategories: new Set<string>([]),
  }),
  actions: {
    setQuery(query: string) {
      this.query = query;
    },
    setCategory(category: string) {
      this.category = category;
    },
    setActiveContentType(contentType: string) {
      this.activeContentType = contentType;
    },
    setActiveCategories(category) {
      if (!category) {
        this.activeCategories.clear();
      }

      const { title } = category;

      if (category.level === 'lvl0' && !this.activeCategories.has(title)) {
        this.clearCategories();
      }

      if (this.activeCategories.size === 2 && !this.activeCategories.has(title)) {
        this.activeCategories.delete(Array.from(this.activeCategories).pop());
      }

      if (this.activeCategories.has(title)) {
        this.activeCategories.delete(title);

        if (category.level === 'lvl0') {
          this.clearCategories();
        }
      } else {
        this.activeCategories.add(title);
      }
    },
    clearCategories() {
      this.category = null;
      this.activeCategories.clear();
    },
    clear() {
      this.clearCategories();
      this.activeContentType = SEARCH_CONTENT_TYPE_ALL;
      this.query = null;
    },
  },
});
