<template>
  <div>
    <div v-for="index in searchActiveIndices" :key="index" class="search-form__results-group" :class="{ 'is-single-type': isSingleContentType }">
      <ais-index :index-name="index">
        <ais-configure :analytics.camel="true" :hits-per-page.camel="indexHitsPerPage[index]" />
        <ais-state-results>
          <template #default="{ results: { hits } }">
            <div v-if="!hits.length" class="search-form__results-group-header is-empty">
              <h2 class="title is-2">
                {{ SEARCH_CONTENT_TYPE_LABELS[contentTypeByIndex[index]] }}
              </h2>
              <p>No {{ SEARCH_CONTENT_TYPE_EMPTY_LABELS[contentTypeByIndex[index]] }} results found.</p>
            </div>
            <div v-else>
              <div class="search-form__results-group-header">
                <h2 class="title is-2">
                  {{ SEARCH_CONTENT_TYPE_LABELS[contentTypeByIndex[index]] }}
                </h2>
                <ais-pagination :padding="1">
                  <template #default="{ currentRefinement, nbPages, nbHits, refine, createURL, isFirstPage, isLastPage }">
                    <featured-pagination
                      :create-url="createURL"
                      :refine="refine"
                      :nb-pages="nbPages"
                      :nb-hits="nbHits"
                      :current-refinement="currentRefinement"
                      :is-first-page="isFirstPage"
                      :is-last-page="isLastPage"
                    />
                  </template>
                </ais-pagination>
              </div>

              <ais-hits>
                <template #default="{ items, sendEvent }">
                  <div v-if="contentTypeByIndex[index] === SEARCH_CONTENT_TYPE_VIDEOS">
                    <featured-videos-list :items="items" :on-click="sendEvent" variant="small" />
                  </div>

                  <div v-if="contentTypeByIndex[index] === SEARCH_CONTENT_TYPE_PAGES">
                    <website-pages-list :items="items" :on-click="sendEvent" :has-menu="!isDigitalScreen" />
                  </div>

                  <div v-if="contentTypeByIndex[index] === SEARCH_CONTENT_TYPE_RESOURCES">
                    <featured-resources-list :items="items" :on-click="sendEvent" variant="small" :has-menu="!isDigitalScreen" />
                  </div>

                  <div v-if="contentTypeByIndex[index] === SEARCH_CONTENT_TYPE_FAQS">
                    <featured-faqs-list :items="items" :on-click="sendEvent" />
                  </div>

                  <div v-if="contentTypeByIndex[index] === SEARCH_CONTENT_TYPE_FILES">
                    <featured-files-list :items="items" :on-click="sendEvent" />
                  </div>
                </template>
              </ais-hits>

              <div v-if="isSingleContentType" class="search-form__results-group-footer">
                <ais-pagination :padding="1">
                  <template #default="{ currentRefinement, nbPages, nbHits, refine, createURL, isFirstPage, isLastPage }">
                    <featured-pagination
                      :create-url="createURL"
                      :refine="refine"
                      :nb-pages="nbPages"
                      :nb-hits="nbHits"
                      :current-refinement="currentRefinement"
                      :is-first-page="isFirstPage"
                      :is-last-page="isLastPage"
                    />
                  </template>
                </ais-pagination>
              </div>
            </div>
          </template>
        </ais-state-results>
      </ais-index>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useRuntimeConfig } from '#app';
import {
  SEARCH_CONTENT_TYPE_ALL,
  SEARCH_CONTENT_TYPE_EMPTY_LABELS,
  SEARCH_CONTENT_TYPE_FAQS,
  SEARCH_CONTENT_TYPE_FILES,
  SEARCH_CONTENT_TYPE_LABELS,
  SEARCH_CONTENT_TYPE_PAGES,
  SEARCH_CONTENT_TYPE_RESOURCES,
  SEARCH_CONTENT_TYPE_VIDEOS,
  SEARCH_RESULTS_PER_PAGE_FAQS,
  SEARCH_RESULTS_PER_PAGE_FILES,
  SEARCH_RESULTS_PER_PAGE_PAGES,
  SEARCH_RESULTS_PER_PAGE_RESOURCES,
  SEARCH_RESULTS_PER_PAGE_VIDEOS,
} from '~/constants/search';
import FeaturedPagination from '~/components/global/components/featured/FeaturedPagination.vue';
import { useResourcesSearchStore } from '~/stores/resources-search';
import FeaturedVideosList from '~/components/global/components/featured/FeaturedVideosList.vue';
import WebsitePagesList from '~/components/global/components/website-pages-list/WebsitePagesList.vue';
import FeaturedResourcesList from '~/components/global/components/featured/FeaturedResourcesList.vue';
import FeaturedFaqsList from '~/components/global/components/featured/FeaturedFaqsList.vue';
import FeaturedFilesList from '~/components/global/components/featured/FeaturedFilesList.vue';

const props = defineProps({
  types: {
    type: Array as () => string[],
    required: true,
  },
  isDigitalScreen: Boolean,
});

const config = useRuntimeConfig();
const resourcesSearch = useResourcesSearchStore();
const activeContentType = computed(() => resourcesSearch.activeContentType);

const pagesIndex = computed(() => config.public.algoliaPagesIndex);
const resourcesIndex = computed(() => config.public.algoliaResourcesIndex);
const videosIndex = computed(() => config.public.algoliaVideosIndex);
const faqsIndex = computed(() => config.public.algoliaFaqsIndex);
const filesIndex = computed(() => config.public.algoliaFilesIndex);

const contentTypeByIndex = computed(() => ({
  [pagesIndex.value]: SEARCH_CONTENT_TYPE_PAGES,
  [resourcesIndex.value]: SEARCH_CONTENT_TYPE_RESOURCES,
  [videosIndex.value]: SEARCH_CONTENT_TYPE_VIDEOS,
  [faqsIndex.value]: SEARCH_CONTENT_TYPE_FAQS,
  [filesIndex.value]: SEARCH_CONTENT_TYPE_FILES,
}));

const indexByContentType = computed(() => invert(contentTypeByIndex.value));
const indexHitsPerPage = computed(() => {
  const arrIdx = activeContentType.value === SEARCH_CONTENT_TYPE_ALL ? 0 : 1;
  return {
    [pagesIndex.value]: SEARCH_RESULTS_PER_PAGE_PAGES[arrIdx],
    [resourcesIndex.value]: SEARCH_RESULTS_PER_PAGE_RESOURCES[arrIdx],
    [videosIndex.value]: SEARCH_RESULTS_PER_PAGE_VIDEOS[arrIdx],
    [faqsIndex.value]: SEARCH_RESULTS_PER_PAGE_FAQS[arrIdx],
    [filesIndex.value]: SEARCH_RESULTS_PER_PAGE_FILES[arrIdx],
  };
});

const searchActiveIndices = computed(() => {
  switch (activeContentType.value) {
    case SEARCH_CONTENT_TYPE_PAGES:
      return [pagesIndex.value];
    case SEARCH_CONTENT_TYPE_RESOURCES:
      return [resourcesIndex.value];
    case SEARCH_CONTENT_TYPE_VIDEOS:
      return [videosIndex.value];
    case SEARCH_CONTENT_TYPE_FAQS:
      return [faqsIndex.value];
    case SEARCH_CONTENT_TYPE_FILES:
      return [filesIndex.value];
    case SEARCH_CONTENT_TYPE_ALL:
    default:
      return props.types?.map((contentType) => indexByContentType.value[contentType]);
  }
});

const isSingleContentType = computed(() => activeContentType.value !== SEARCH_CONTENT_TYPE_ALL);
</script>
